
import Vue from "vue";
import ErrorIcon from "@/components/atoms/ErrorIcon.vue";
import SiteFooter from "@/components/organisms/Footer.vue";

export default Vue.extend({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { ErrorIcon, SiteFooter },
  created() {
    window.scrollTo(0, 0);
  },
  computed: {
    description() {
      let description = "Nolaのプレミアム会員に登録すると、有料の機能を利用できるようになります。";
      const { from } = this.$route.query;
      if (from) {
        description = "この機能は、Nolaのプレミアム会員に登録すると利用ができるようになります。";
      }
      return description;
    },
  },
  methods: {
    onClickTransitionPlanSelection() {
      this.$ga.event("subscription", "transition-plan-selection", "プラン選択に移動", 0);
    },
  },
});
